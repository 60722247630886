@import "../../common/valiables";

.box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  -webkit-transition: all 1.2s ease;
  transition: all 1.2s ease;
  color: $sub-color_white;
  background-color: rgba($main-color, 0.5);

  * {
    letter-spacing: normal;
    white-space: normal;
  }
}

/* ローディング表示を消すための定義 */
.box.loaded {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center;
  width: 120px;
  height: 120px;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
  }

  &.type {
    border-radius: 50%;
    border-width: 4px;
    border-style: solid;
    border-color: $sub-color_white transparent;
    animation: spinner1_1 2.2s infinite linear forwards;

    &::before {
      content: '';
      position: absolute;
      top: 4px;
      left: 4px;
      right: 4px;
      bottom: 4px;
      border-radius: 50%;
      border-width: 3px;
      border-style: solid;
      border-color: $sub-color_white transparent;
      animation: spinner1_2 .8s infinite linear forwards reverse;
    }

    span {
      animation: spinner_loading_text 2.2s infinite linear forwards reverse;
    }
  }
}

// Spinner animation
@keyframes spinner1_1 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes spinner1_2 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner_loading_text {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg);
  }

  50% {
    opacity: 0;
    transform: translate(-50%, -50%) rotate(180deg);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(360deg);
  }
}