@import "../../common/valiables";
.footer{
  // 色・形・大きさ
  background-color: $main-color;
  border-top: $sub-color_orange 1px solid;
  display: flex;
  font-size: 1.2em;
  color: $font_black;
  font-family: $font_mincho;
  font-weight: bold;
  // 配置
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: $footer_margin;

  .footer-button{
    // 配置
    display: flex;
    align-items: center;
    justify-content: space-between;
    // 色・形・大きさ
    border: $sub-color_orange 1px solid;
    border-radius: $radius_middle;
    margin: $space_middle $space_small;
    padding: $space_small $space_middle;
    width: 100%;
    cursor: pointer;
    // 背景画像
    background-color: $sub-color_orange;
    background-image: url(../../assets/img/button_bk.png);
    background-size: cover;
    img{
      width: 24px;
    }
  }
}