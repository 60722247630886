@import "../../common/valiables";

.no-result-wrapper {
  padding: $space_middle;
  display: flex;
  align-content: center;
  justify-content: center;

  .display-no-result {
    font-size: 1rem;
  }
}