@import "../../common/valiables";

.devil-fusion-wrapper {
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.select-devil-wrapper {
  padding: $space_middle 0;
  text-align: center;

  .select-devil {
    display: flex;
    justify-content: center;
  }

  .fusion-icon {
    font-size: 1.5rem;
    display: inline-block;
    transform: rotate(45deg);
    margin: -$space_middle 0;
  }
}

.combo-wrapper {
  position: relative;
  margin: $space_small;
  transform: scale(1.2);

  &::after {
    content: "";
    width: .6em;
    height: .6em;
    border-top: 2px solid rgba($color: $font_black, $alpha: 0.8);
    border-right: 2px solid rgba($color: $font_black, $alpha: 0.8);
    position: absolute;
    top: 50%;
    right: 13%;
    transform: translate(-100%, -75%) rotate(135deg);
  }

  .combo {
    background-color: white;
    border-radius: $radius_small;
    padding: $space_middle;
    border: 1px solid grey;
    width: $combo_size;
    box-sizing: border-box;
    text-align: left;
  }
}

.check-box {
  padding: $space_middle;
  margin: $space_middle;
  border: $sub-color_white 1px solid;
  min-width: 112px;

  .option-title {
    // font-size: 1rem;
    font-weight: bold;
    text-align: center;
  }

  .option-contents {
    .option {
      margin: $space_small 0;

      .option-name {
        font-size: $font_size;
      }
    }
  }

}