@import "../../common/valiables";

.sort {
  font-size: .95em;
  line-height: 1em;
}

.modal-wrapper {
  background-image: url(../../assets/img/chara1.png);
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
  // background-blend-mode: color;
  box-sizing: unset;

  .modal-title {
    margin-top: 0;
    text-align: center;
  }

  .modal-main {
    display: flex;

    .sort-content {
      width: 50%;
      display: flex;
      flex-direction: column;

      &.data {
        margin-right: $space_middle;
        padding: 0 $space_middle;
        border-left: solid 1px rgba($font_white, 0.5);
        border-right: solid 1px rgba($font_white, 0.5);
      }
    }
  }
}