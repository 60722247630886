@import "../../common/valiables";

.devil-fusion {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-top: rgba($font_white, 0.5) 1px solid;
  padding: 4px 8px;

  .fusion-icon {
    font-weight: bold;
    // margin: -$space_small;

    &.add {
      transform: rotate(45deg);
    }
  }

  .fusion-content {
    width: $card_size;
  }
}

// 逆引き用スタイル
.devil-fusion {
  .fusion-icon {
    &.reverse-equal {
      order: 2;
    }

    &.reverse-expression {
      order: 4;
    }
  }

  .fusion-content {
    &.reverse-result {
      order: 1;
    }

    &.reverse-src1 {
      order: 3;
    }

    &.reverse-src2 {
      order: 5;
    }
  }
}