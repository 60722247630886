@import "../../common/valiables";

.filter-wrapper {
  // position: absolute;

  .filter-button {
    position: fixed;
    bottom: $footer_margin;
    right: 0;
    z-index: 2;
    margin: $space_middle;
    background-color: rgba($sub-color_white, 0.8);
    border: $sub-color_white 1px solid;
  }

  .filter-option {
    display: flex;
    justify-content: space-between;
    margin: $space_middle;
  }

  .filter-devil {
    display: flex;
    padding: $space_middle;
    margin: $space_middle;
    border-radius: $radius_small;

    .element {
      width: 20%;
      text-align: center;
      font-size: .9rem;
      margin: auto;
    }

    .element-combo-wrapper {
      width: 100%;
    }

    background: linear-gradient(rgba($sub-color_white, 0.2), rgba($sub-color_white, 0.3));

    &.devil1 {
      background: linear-gradient(rgba($sub-color_red, 0.7), rgba($sub-color_red, 1.0));
    }

    &.devil2 {
      background: linear-gradient(rgba($sub-color_blue, 0.7), rgba($sub-color_blue, 1.0));
    }

    &.devil3 {
      background: linear-gradient(rgba($sub-color_purple, 0.7), rgba($sub-color_purple, 1.0));
    }
  }
}

.modal-wrapper {
  .modal-title {
    margin-top: 0;
    text-align: center;
  }
}