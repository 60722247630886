@import "common/valiables";

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $font_size;
  color: $font_white;
  background-color: $main-color;

  input {
    font-size: 16px;
    transform: scale(0.75);
  }

  .main {
    // min-height: 667px - $header_margin - $footer_margin;
    margin-top: $header_margin;

    // background-image:
    //   url(./assets/img/chara3.png),
    //   url(./assets/img/bg_top.png),
    // url(./assets/img/bg_bottom.png);
    // background-repeat: no-repeat;
    // background-position: center, top, bottom;
    // background-position: bottom;
    // background-size: contain;
    // background-blend-mode: color;

    &.toppage {
      height: 100;
      min-height: 667px - $header_margin;
    }

    &:not(.toppage) {
      margin-bottom: $footer_margin;
    }

  }

  * {
    box-sizing: border-box;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}