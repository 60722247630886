@import "../../common/valiables";

.header {
  height: $header_margin;
  width: 100%;
  background-color: $main-color;
  // background-image: url(../../assets/img/header_bg.jpg);
  background-size: cover;
  border-bottom: $sub-color_orange 1px solid;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-size: 2em;
    font-family: $font_mincho;
    font-weight: bold;
    text-shadow: 0 0 $font_border $sub-color_orange, 0 0 $font_border $sub-color_orange, 0 0 $font_border $sub-color_orange, 0 0 $font_border $sub-color_orange;
    letter-spacing: 2px;
  }
}

.left-button {
  display: flex;
}

.header-button {
  color: $font_black;
  border: $sub-color_orange 1px solid;
  border-radius: $radius_middle;
  margin: $space_small;
  cursor: pointer;
  height: 40px;
  // 背景画像
  background-color: $sub-color_orange;
  background-image: url(../../assets/img/button_bk.png);
  background-size: cover;

  &.sort,
  &.pagetop,
  &.toppage {
    padding: $space_middle;
  }

  img {
    width: 20px;
    height: 20px;
  }

  &.hidden {
    display: none;
  }
}