@import "../../common/valiables";

.special-m3-wrapper {
  .filter-devil {
    padding: $space_small;
    width: 100%;
    height: 50px;
    z-index: 2;
    position: fixed;
    top: $header_margin;
    background-color: $main-color;
    border-bottom: rgba($sub-color_white, 0.5) 1px solid;
  }

  .main-contents {
    margin-top: $header_margin + 50px;
  }
}