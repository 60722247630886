@import "../../common/valiables";

.devil-content {
  padding: $space_small;
  border-radius: $radius_small;
  background: linear-gradient(rgba($font_white, 0.1), rgba($font_white, 0.4));
  cursor: pointer;

  &.result {
    background: linear-gradient(rgba($sub-color_orange, 0.1), rgba($sub-color_orange, 0.9));
  }

  &.no-result {
    background: $main-color;
    border: 1px solid rgba($sub-color_white, 0.5);
  }

  &.spirit {
    background: linear-gradient(rgba($sub-color_green, 0.1), rgba($sub-color_green, 0.9));
  }

  &:hover {
    animation: gradationTest 1s infinite;
  }

  &:active {
    background: linear-gradient(rgba($font_white, 0.2), rgba($font_white, 0.5));
  }

  .info {
    display: flex;
    color: rgba($font_white, 0.8);

    .devil-image {
      height: 56px;
      width: 56px;
      object-fit: cover;
      border-radius: $radius_small;
      // display: none;
    }

    .devil-init-name {
      height: 56px;
      width: 56px;
      border-radius: $radius_small;
      background-color: rgba($main-color, 0.5);
      font-size: 1.5rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .image-wrap {
      position: relative;
    }

    .element {
      width: 80%;
      text-align: center;
      border-radius: 100px;
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translate(-50%, -40%);

      &.result {
        background: linear-gradient(rgba($sub-color_orange, 0.7), rgba($sub-color_orange, 1.0));
      }

      &.no-result {
        background: $main-color;
        border: 1px solid rgba($sub-color_white, 0.5);
      }

      &.devil1 {
        background: linear-gradient(rgba($sub-color_red, 0.7), rgba($sub-color_red, 1.0));
      }

      &.devil2 {
        background: linear-gradient(rgba($sub-color_blue, 0.7), rgba($sub-color_blue, 1.0));
      }

      &.sacrifice {
        background: linear-gradient(rgba($sub-color_purple, 0.7), rgba($sub-color_purple, 1.0));
      }

      &.spirit-up,
      &.spirit-down {
        background: linear-gradient(rgba($sub-color_green, 0.7), rgba($sub-color_green, 1.0));
      }
    }

    .devil-info {
      margin-left: $space_middle;
    }
  }

  .name {
    margin-top: $space_small;
  }
}

@keyframes gradationTest {
  0% {
    background-color: rgba($font_white, 0.1);
  }

  50% {
    background-color: rgba($font_white, 0.2);
  }
}