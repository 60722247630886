$main-color: #000000;
$sub-color_orange: #A77F44;
$sub-color_green: #44a777;
$sub-color_red: #861928;
$sub-color_blue: #192886;
$sub-color_purple: #771986;
$sub-color_white: #ffffff;

$font_white: #ffffff;
$font_black: #212121;
$font_size: 12px;
$font_border: 3px;
$font_mincho: serif;

$space_small: 4px;
$space_middle: 8px;
$header_margin: 48px;
$footer_margin: 64px;

$radius_small: 3px;
$radius_middle: 5px;
$radius_large: 10px;

$combo_size: 100%;
$card_size: 31%;