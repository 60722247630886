@import "../../common/valiables";

.devil-list-wrapper{
  .devil-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .list-child{
      width: $card_size;
      margin: $space_small;
    }
  }  
}