@import "../../common/valiables";

.devil-spec-fusion {
  border-top: rgba($font_white, 0.5) 1px solid;
  padding: 4px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .result-devil {
    width: 100%;
  }

  .sauce-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .sauce-repeat {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: $space_small 0;
    }
  }

  .accordion-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.hidden {
      display: none;
    }
  }

  .spec-fusion-icon {
    font-weight: bold;

    &.equal {
      transform: rotate(90deg);
    }

    &.add {
      transform: rotate(45deg);

      &.first {
        visibility: hidden;
      }
    }
  }
}

.hoge {
  background-color: red;
}

.hoge-detail {
  background-color: black;
}